import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

import { Carousel } from './Carousel'
import { Includes } from './Includes'
import { Legend } from './Legend'
import { Package, Props as PackageProps } from './Package'
import { Validity } from './Validity'

export interface Props {
  galleryImages: ImageProps[]
  galleryThumbnails?: ImageProps[]
  includes?: string[]
  includesTitle?: string
  languageCode: string
  legend?: string[]
  packages?: PackageProps[]
  validity?: string
  validityTitle?: string
}

export const OfferContent = memo(function OfferContent({
  galleryImages,
  galleryThumbnails,
  includes,
  includesTitle,
  languageCode,
  legend,
  packages,
  validity,
  validityTitle,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {includes ? <Includes list={includes} title={includesTitle} /> : null}

        {legend ? <Legend list={legend} /> : null}

        <Validity description={validity} title={validityTitle} />

        {galleryImages ? (
          <Carousel
            images={galleryImages}
            languageCode={languageCode}
            thumbnails={galleryThumbnails}
          />
        ) : null}

        {packages ? (
          <Packages>
            {packages.map((item, index) => (
              <Package key={index} {...item} />
            ))}
          </Packages>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
`

const Wrapper = styled.div`
  padding: 4rem 19.722vw 11.5625rem;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    padding-top: 3.1875rem;
    padding-bottom: 8.4375rem;
  }
`

const Packages = styled.div`
  margin-top: 3.75rem;

  @media (max-width: 767px) {
    margin-top: 5rem;
  }
`
