import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Rumble } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  list: string[]
  title?: string
}

export const Includes = memo(function Includes({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {list.map((item: any, index) => (
        <Item key={index}>
          <FadeInUp>
            <Rumble />
            <Label>{item.description}</Label>
          </FadeInUp>
        </Item>
      ))}
    </Container>
  )
})

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  line-height: 1.5rem;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`

const Item = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 1rem;

  > div {
    display: flex;
    align-items: flex-start;
    border-bottom: 0.0625rem solid
      ${({ theme }) => theme.colors.variants.neutralLight1};
    padding: 1rem 0;
  }

  svg {
    width: 1rem;
    height: auto;
    margin-right: 0.75rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    transform: translateY(0.1875rem);
  }
`

const Label = styled.span`
  width: calc(100% - 1.75rem);
`
