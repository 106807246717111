import styled from '@emotion/styled'
import { Rumble } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  description?: string
  discount?: string
  languageCode: string
  priceLabel?: string
  priceValue?: string
  subtitle?: string
  title: string
  validity?: string
}

export const OfferIntro = memo(function OfferIntro({
  description,
  discount,
  languageCode,
  priceLabel,
  priceValue,
  subtitle,
  title,
  validity,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      {discount || priceValue || validity ? (
        <Head dial={5} row wrap>
          {validity ? (
            <Validity dial={4} row>
              <Rumble />
              {validity}
            </Validity>
          ) : null}

          {priceValue ? (
            <Price dial={4} row>
              <Rumble />
              <Label>{useVocabularyData('from', languageCode)}:</Label>
              <Value>{priceValue}</Value>
              {priceLabel ? <PriceLabel>{priceLabel}</PriceLabel> : null}
            </Price>
          ) : discount ? (
            <Price dial={4} row>
              <Rumble />
              <Label>{useVocabularyData('up-to', languageCode)}:</Label>
              <Value>{discount}</Value>
            </Price>
          ) : null}
        </Head>
      ) : null}

      <Wrapper>
        <Title>{title}</Title>
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Head = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  line-height: 1.375rem;
  padding: 0 19.722vw;

  > div {
    flex: 1;
    padding-top: 2.375rem;
    padding-bottom: 2.375rem;
    &:first-of-type {
      padding-right: 5.139vw;
    }
    &:nth-of-type(2) {
      border-left: 0.0625rem solid
        ${({ theme }) => theme.colors.variants.primaryDark};
      padding-left: 5.139vw;
    }
  }

  svg {
    width: 1rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-right: 0.75rem;
  }

  @media (max-width: 1023px) {
    display: block;
    padding: 2.875rem 2rem;

    > div {
      padding: 0;
      &:first-of-type {
        padding: 0;
      }
      &:nth-of-type(2) {
        border: none;
        margin-top: 1.25rem;
        padding: 0;
      }
    }
  }
`

const Validity = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
`

const Price = styled(FlexBox)``

const PriceLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  margin-left: 0.75rem;
  text-transform: uppercase;
`

const Label = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  margin-right: 1rem;
  text-transform: capitalize;
`

const Value = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.5rem;
`

const Wrapper = styled.div`
  padding: 7.0625rem 19.722vw 5.9375rem;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    padding-top: 3.375rem;
    padding-bottom: 3.375rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 0.9375rem;
    line-height: 1.5rem;
    margin-top: 0.125rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`
