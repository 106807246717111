import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  beURL?: string
  contactsURL?: string
  description?: string
  image?: ImageProps
  languageCode: string
  sort?: number
  title?: string
}

export const Package = memo(function Package({
  beURL,
  contactsURL,
  description,
  image,
  languageCode,
  title,
}: Props) {
  return (
    <Container row stretch wrap>
      {image ? (
        <LeftSide>
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </LeftSide>
      ) : null}

      <RightSide>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}

        {beURL || contactsURL ? (
          <Buttons row>
            {beURL ? (
              <Button
                label={useVocabularyData('book', languageCode)}
                target="_blank"
                URL={beURL}
              />
            ) : null}

            {contactsURL ? (
              <Button
                label={useVocabularyData('ask-for', languageCode)}
                target="_blank"
                URL={contactsURL}
                variant="primary"
              />
            ) : null}
          </Buttons>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 1.25rem;
  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    display: block;
    margin-top: 1.5rem;
    text-align: center;
  }
`

const LeftSide = styled.div`
  width: calc(25% - 1.25rem);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const RightSide = styled.div`
  flex: 1;
  padding: 2.625rem 3.125rem;

  @media (max-width: 767px) {
    padding: 3.5625rem 2.3125rem 2.9375rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 0.75rem;
`

const Buttons = styled(FlexBox)`
  margin-top: 3.125rem;

  a {
    min-width: 10rem;
    &:nth-of-type(2) {
      margin-left: 0.75rem;
    }
  }

  @media (max-width: 767px) {
    display: block;
    margin-top: 2.25rem;

    a {
      width: 100%;
      &:nth-of-type(2) {
        margin: 0.625rem 0 0;
      }
    }
  }
`
